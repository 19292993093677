import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseTranslationService } from 'src/app/app-services/base.translation.service';
import { LocalizationService } from 'src/app/app-services/localization.service';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent extends BaseTranslationService implements OnInit {

  message: string = 'spdf.app.module.buup.confirmation.dialog.message'
  confirmButtonText = 'spdf.app.module.buup.confirmation.dialog.confirmButtonText'
  cancelButtonText = 'spdf.app.module.buup.confirmation.dialog.cancelButtonText'
  title = ''

  constructor(private dialogRef: MatDialogRef<ConfirmationDialogComponent>, localization: LocalizationService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    super(localization);
  
      if(data){
        this.message = data.message || this.message;
        this.title = data.title || this.title;
        if (data.buttonText) {
          this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
          this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
        }
      }
  }

  ngOnInit(): void {
  }

  ok(): void {
    this.dialogRef.close(true);
  } 

}
