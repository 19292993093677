import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges
} from '@angular/core';
import {
  createEditableGridConfig,
  EditableGridConfig,
  EditableGridManager,
  FieldConfigDTO,
  GridDatePickerEditorComponent,
  GridSelectEditorComponent,
  GridSelectEditorParams,
  SelectItem,
} from '@basware/gt-editable-grid';
import {
  GravitonGridCommandEventTypes,
  GravitonGridEvent,
  GravitonGridEventTypes,
  GridManager,
  GridRowActionManager,
  GridRowIconHelper,
  GridValidationManager,
  GtGridConfig,
  GTGRID_CONFIG,
  RowActionHelper,
  ROW_ACTION_COLUMN_NAME,
  UpdateRowsCommandEvent,
  RowsUpdatedEvent,
} from '@basware/gt-grid';
import { AgGridEvent, Events, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { BehaviorSubject, combineLatest, Subject, map, takeUntil } from 'rxjs';

import {
  EditableValidatingGridActionManager,
  EditableValidatingGridValidationManager,
} from './managers';
import { TaxData } from '../taxes-and-sums.model';
import { BaseTranslationService } from 'src/app/app-services/base.translation.service';
import { LocalizationService } from 'src/app/app-services/localization.service';
import { GlobalizeService } from 'src/app/app-services/globalize.service';
import { AppConfig } from 'src/app/app.config';
import * as moment from 'moment';
import { FormatWidth, getLocaleDateFormat } from '@angular/common';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { AppUtils } from '../../app-util.service';
import { DecimalValidator } from 'src/app/validators/decimal.validator';

@Component({
  selector: 'taxes-editable-validating-grid',
  templateUrl: './editable-validating-grid.component.html',
  styleUrls: ['./editable-validating-grid.component.scss'],
  providers: [
    EditableGridManager,
    EditableValidatingGridValidationManager,
    { provide: GridManager, useExisting: EditableGridManager },
    {
      provide: GridRowActionManager,
      useClass: EditableValidatingGridActionManager,
    },
    {
      provide: GridValidationManager,
      useExisting: EditableValidatingGridValidationManager,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditableValidatingGridForTaxes extends BaseTranslationService implements OnInit, OnDestroy {

  @Input() rowData: TaxData[] = [];
  @Input() isDisabled: boolean = true;
  @Input() reloadGrid: boolean;
  @Output() updatedRowData = new EventEmitter<any>();
  @Output() isGridValid = new EventEmitter<any>();
  @Output() readFocusOnCanvas = new EventEmitter<any>();
  gridConfig: EditableGridConfig<TaxData>;
  searchText: any;
  showGrid: boolean = false;

  /**
   * The application is responsible for tracking data. This is set to
   * true if user makes any changes and enables the save/cancel buttons.
   */
  isDirty$ = new BehaviorSubject<boolean>(false);
  isValid$ = this.gridValidationManager.isValid$;
  isGridValid$ = combineLatest([this.isDirty$, this.isValid$]).pipe(
    map(([isDirty, isValid]) => (isDirty && isValid))
  );

  private destroy$ = new Subject<void>();
  localeTextFunc = (key: string) => {
    return this.tr(key).toString();
  }

  constructor(
    private gridManager: EditableGridManager<TaxData>,
    private gridValidationManager: EditableValidatingGridValidationManager,
    protected utils: AppUtils,
    localization: LocalizationService,
    @Inject(GTGRID_CONFIG) private gtGridConfig: GtGridConfig
  ) {
    super(localization);
    this.gridConfig = this.createGridConfig();
    this.isGridValid$.subscribe(val => {
      this.isGridValid.emit(val);
    })
  }

  ngOnInit(): void {
    this.subscribeGridEvents();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ("reloadGrid" in changes) {
      this.displayGrid(this.rowData);
      if (this.isGridReady) {
        this.setGridData();
      }
    }
    if ("isDisabled" in changes) {
      if (this.gridManager?.columnApiQueue.api)
        this.gridSettings(!this.isDisabled);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private subscribeGridEvents(): void {
    this.gridManager.events$
      .pipe(takeUntil(this.destroy$))
      .subscribe((event: any) => this.onGridEvents(event));
  }

  private isGridReady = false;
  private onGridEvents(
    event: GravitonGridEvent | AgGridEvent<TaxData>
  ): void {
    switch (event.type) {
      case GravitonGridEventTypes.GridReady: {
        this.isGridReady = true;
        this.setGridData();
        this.gridManager.columnApi.autoSizeColumn(ROW_ACTION_COLUMN_NAME);
        break;
      }
      case GravitonGridCommandEventTypes.UpdateRows:
        break;
      case Events.EVENT_CELL_EDITING_STOPPED: {
        // user has edited a cell or added or deleted a row
        this.isDirty$.next(true);
        this.emitRowData();
        break;
      }
      case GravitonGridEventTypes.RowsUpdated: {
        this.isDirty$.next(true);
        this.emitRowData();
        break;
      }
      case Events.EVENT_CELL_EDITING_STARTED: {
        setTimeout(() => {
          let focusedCell = this.gridManager.api.getFocusedCell()
          if (focusedCell && focusedCell.rowIndex > -1) {
            let row = this.gridManager.api.getDisplayedRowAtIndex(focusedCell?.rowIndex);
            if (row) {
              var column = (row.data as any)[focusedCell.column.getColId()]
              if (column && column["data"])
                this.readFocusOnCanvas.emit(column["data"]);
              else
                this.readFocusOnCanvas.emit(column);
            }
          }
        }, 1);
        break;
      }
    }
  }
  _count: number = 0;
  private setGridData(): void {
    this.gridManager.api.setRowData(this.rowData);
    this.isDirty$.next(true);
    this.gridValidationManager.clear();
    this.gridValidationManager.validateGridData();
  }

  gridSettings(editable: boolean) {
    // enable/disable column editing
    this.gridManager.columnApi?.getAllGridColumns().forEach(col => {
      if (col.getColId() != "statusIconColId" && col.getColId() != "gridActions")
        col.getColDef().editable = editable
    });
    // disable/enable action buttons of each aggrid row
    let taxGridElement = document.getElementById("tax-data-grid") as HTMLElement;
    let taxGridRows = taxGridElement.getElementsByTagName("gt-row-action") as HTMLCollection;
    Array.from(taxGridRows).forEach(el => {
      let actionButton = el.getElementsByTagName("button")[0] as HTMLButtonElement;
      actionButton.disabled = !editable;
      editable ? actionButton.classList.remove("mat-button-disabled") : actionButton.classList.add("mat-button-disabled")
    })
    //disable/enable row selection
    this.gridManager.api.forEachNode(row => {
      row.selectable = editable;
      row.setSelected(false);
    });
  }

  addRow(): void {
    if (this.rowData.length == 0) {
      this.rowData = [new TaxData(1)];
      this.displayGrid(this.rowData);
    } else {
      let lastRowNumber = this.gridManager.api.getDisplayedRowAtIndex(this.rowData.length - 1)?.data?.rowNumber;
      this._count = (lastRowNumber ?? 0) + 1;
      this.gridManager.dispatch(
        new UpdateRowsCommandEvent({
          add: [new TaxData(this._count++)],
          update: [],
          remove: [],
        })
      );
    }
  }

  getUpdatedRowData() {
    let _data: any[] = [];
    this.gridManager.api.forEachNode(node => {
      _data.push(node.data)
    })
    this.displayGrid(_data);
    return _data;
  }

  displayGrid(gridData: any[]) {
    if (gridData.length == 0) {
      this.showGrid = false;
      (document.getElementById('tax-data-grid') as HTMLElement).style.height = '0px';
    } else {
      this.showGrid = true;
      (document.getElementById('tax-data-grid') as HTMLElement).style.height = 42 + (.5 / gridData.length) + gridData.length * 40.3 + 'px';
    }
  }

  emitRowData() {
    this.updatedRowData.emit(this.getUpdatedRowData());
  }

  private defaultFieldConfig = {
    mandatory: false,
    visible: true,
    suppressMenu: true,
    entityType: 'TaxData',
    flex: 1
  };
  private fieldConfigs: FieldConfigDTO<TaxData>[] = [
    {
      ...this.defaultFieldConfig,
      bindingPath: 'description',
      name: { key: '', value: this.tr('spdf.app.module.tax.lines.grid.header.taxType').toString() },
      format: 'text',
      mandatory: false,
      flex: 2,
      sort: null,
      colDefPassthrough: {
        valueSetter: (params) => this._valueSetter(params)
      },
      valueGetter: (params) => this._valueGetter(params),
    },
    {
      ...this.defaultFieldConfig,
      alignment: "Right",
      bindingPath: 'taxRate',
      name: { key: '', value: this.tr('spdf.app.module.invoice.lines.grid.header.taxRate').toString() },
      format: 'text',
      colDefPassthrough: {
        headerClass: "ag-right-aligned-header",
        valueSetter: (params) => this._valueSetter(params)
      },
      valueGetter: (params) => this.decimalValueGetter(params),
      valueFormatter: (params) => this.taxRateValueFormatter(params),
    },
    {
      ...this.defaultFieldConfig,
      bindingPath: 'taxAmount',
      name: { key: '', value: this.tr('spdf.app.module.invoice.lines.grid.header.taxSum').toString() },
      alignment: "Right",
      format: 'text',
      colDefPassthrough: {
        headerClass: "ag-right-aligned-header",
        valueSetter: (params) => this._valueSetter(params)
      },
      valueGetter: (params) => this.decimalValueGetter(params)
    }
  ];

  createGridConfig(): EditableGridConfig<TaxData> {
    return createEditableGridConfig<TaxData>({
      configDtos: this.fieldConfigs,
      extraColumns: [
        {
          ...GridRowIconHelper.createRowIconColDef(
            this.gridValidationManager,
            this.gtGridConfig
          ),
          cellStyle: { 'justify-content': 'center' },
          suppressMenu: true,
          minWidth: 40,
          maxWidth: 45,
        }, {
          ...RowActionHelper.createActionColDef('Actions'),
          cellStyle: { 'justify-content': 'center' },
          suppressMenu: true,
          minWidth: 40,
          maxWidth: 45,
        }
      ],
      getRowId: params => params.data.rowNumber.toString(),
      rowSelection: 'single',

    });
  }

  taxRateValueFormatter(params: ValueFormatterParams<TaxData>) {
    if (params.data && params.data.taxRate) {
      return params.data.taxRate["value"] + '%';
    } else {
      return '%'
    }
  }

  _valueGetter(params: any) {
    let field: any = params.column.getColDef().field as string;
    field = (params.data as any)[field];
    if (field)
      return field["value"];
    return '';
  }

  _valueSetter(params: any) {
    let fieldName: any = params.column.getColDef().field as string;
    let field = (params.data as any)[fieldName];
    if (!field) {
      params.data[fieldName] = { "value": "", data: {} }
    }
    params.data[fieldName]["value"] = params.newValue.trim();
    return true;
  }

  decimalValueGetter(params: any) {
    let field = params.column.getColDef().field as string;
    if (!(params.data as any)[field]) {
      return '';
    }
    let value = (params.data as any)[field]["value"];
    if (value) {
      if (!DecimalValidator.decimalValidatorForValue(value) && !isNaN(Number(GlobalizeService.unformatNumber(value, AppConfig.USER_CULTURE)))) {
        value = AppUtils.localizeDecimal(value);
      };
    }
    else value = '';
    (params.data as any)[field]["value"] = value;
    return value;
  }

  dateValueFormatter(params: ValueFormatterParams<TaxData>) {
    let field = params.column.getColDef().field as string;
    let value = (params.data as any)[field];
    if (value) {
      let dateVal = moment.utc(value).locale(AppConfig.USER_CULTURE || AppConfig.BROWSER_CULTURE);
      console.log(dateVal.format('L'))
      console.log(dateVal.localeData().longDateFormat('L'))
      return dateVal.format('L');
    }
    else return '';
  }
}