import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../app-services/common.service';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from '../app-services/api.service';
import { LocalizationService } from '../app-services/localization.service';
import { BaseTranslationService } from '../app-services/base.translation.service';
import { SubSink } from 'subsink';
import { MatTableDataSource } from '@angular/material/table';
import { AppConfig } from '../app.config';
import { MatSort, Sort } from '@angular/material/sort';
import { ConfigurationReport } from '../models/ConfigurationReport';
import { SmartpdfApiService } from '../app-services/smartpdf.api.service';
import { ApprovalDialogComponent } from './approveDialog/approval-dialog.component';
import { environment } from 'src/environments/environment';
import { MatPaginator } from '@angular/material/paginator';
declare function aptrinsic(t: any, s: any): any;
@Component({
  selector: 'app-configuration-reports',
  templateUrl: './configuration-reports.component.html',
  styleUrls: ['./configuration-reports.component.scss']
})
export class ConfigurationReportsComponent extends BaseTranslationService implements OnInit, AfterViewInit {

  subs = new SubSink();
  flexValue: number;
  filterValue: string = '';

  columnsToDisplay = ['date', 'configurationName', 'status', 'issuer', 'issuerComment', 'approver', 'approverComment', 'actions'];
  dataSource = new MatTableDataSource<ConfigurationReport>();
  sortByItems = sortByItems;

  isContentLoading: boolean = true;

  progressBarValue = AppConfig.PROGRESS_BAR_VALUE;
  progressBarBufferValue = AppConfig.PROGRESS_BAR_BUFFER_VALUE;

  matPaginatorPageSizeOptions = AppConfig.MATERIAL_PAGINATOR_PAGE_SIZE_OPTIONS;
  pageSize = 10;

  sortValue: any = this.sortByItems[0];

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(public router: Router,
    public common: CommonService,
    public dialog: MatDialog,
    public smartpdfApiService: SmartpdfApiService,
    localization: LocalizationService) {

    super(localization);
    this.subs.add(this.common.flexValue.subscribe(
      value => { this.flexValue = value }
    ))
  }

  ngAfterViewInit() {
    this.dataSortingAccessor();
    this.dataSource.sort = this.sort;
    this.applySort(this.sortValue);
    this.dataFilterPredicate();
    this.dataSource.paginator = this.paginator;
    aptrinsic('track', 'smartPdfConfigurationPageLoaded');
  }

  applySort(sortOn: any) {
    const sortState: Sort = { active: sortOn.column, direction: sortOn.direction };
    this.sort.active = sortState.active;
    this.sort.direction = sortState.direction;
    this.sort.sortChange.emit(sortState);
  }

  applyFilter(filterValue: any) {
    if (filterValue != null) {
      filterValue = filterValue.trim().toLowerCase();
    }
    this.dataSource.filter = filterValue;
  }

  goBack() {
    this.router.navigateByUrl("");
  }

  clearFilterValue() {
    this.filterValue = '';
    this.applyFilter('');
  }

  ngOnInit(): void {
    if (!environment.approvalFlowFeatureFlag) {
      this.goBack();
    }
    this.getConfigurations();
  }

  getConfigurations() {
    this.isContentLoading = true;
    this.smartpdfApiService.getConfigurations().subscribe({
      next: (data) => {
        this.isContentLoading = false;
        this.dataSource.data = data;
      },
      error: (err) => {
        this.isContentLoading = false;
        console.log(err);
      }
    })
  }

  approveConfiguration(config: ConfigurationReport) {
    var dialogRef = this.dialog.open(ApprovalDialogComponent, {
      width: '480px',
      data: {
        title: 'spdf.app.module.smartpdf.configuration.approve.config',
        confirmButtonText: 'spdf.app.module.smartpdf.configuration.approve',
        config: config,
        status: 'approved'
      }
    })

    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.getConfigurations();
      }
    })
  }

  rejectConfiguration(config: ConfigurationReport) {
    var dialogRef = this.dialog.open(ApprovalDialogComponent, {
      width: '480px',
      data: {
        title: 'spdf.app.module.smartpdf.configuration.reject.config',
        confirmButtonText: 'spdf.app.module.smartpdf.configuration.reject',
        config: config,
        status: 'rejected'
      }
    })

    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.getConfigurations();
      }
    })
  }

  downloadReport(config: ConfigurationReport) {
    config["downloading"] = true;
    this.smartpdfApiService.downloadReport(config.configurationId, config.approval.snapshotuuid).subscribe({
      next: (data) => {
        config["downloading"] = false;
        if (data) {
          const source = `data:application/pdf;base64,${data["body"]}`;
          const link = document.createElement("a");
          link.hidden = true;
          link.href = source;
          let approvedDate: Date = new Date(config.approval.approvedOn);
          let basename = `${config.configurationName}_${("0" + approvedDate.getDate()).slice(-2)}-${("0" + (approvedDate.getMonth() + 1)).slice(-2)}-${approvedDate.getFullYear()}`;
          link.download = basename + '.pdf'
          link.click();
        }
      },
      error: (err) => {
        config["downloading"] = false;
        console.log(err);
      }
    })
  }

  dataFilterPredicate() {
    this.dataSource.filterPredicate = (data, filter) => {
      return this.checkIfIncludes(data.configurationName, filter) ||
        this.checkIfIncludes(data.approval.status, filter) ||
        this.checkIfIncludes(data.approval.issuer, filter) ||
        this.checkIfIncludes(data.approval.issuerComment, filter) ||
        this.checkIfIncludes(data.approval.approverComment, filter) ||
        this.checkIfIncludes(data.approval.approver, filter) ||
        (data.approval.status == 'Approved' ? data.approval.approvedOn : data.approval.issuedOn).includes(filter);
    };
  }

  checkIfIncludes(value: any, filter: string) {
    return value ? value.toLocaleLowerCase().includes(filter) : false
  }

  dataSortingAccessor() {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'date':
          return item.approval.status == 'Approved' ? item.approval.approvedOn : item.approval.issuedOn;
        default: return "";
      }
    };
  }

  getLocalizedConfigStatus(status: string) {
    return this.tr(`spdf.app.module.smartpdf.configuration.status.${status}`) || '';
  }
}


export const sortByItems = [
  {
    column: 'date',
    value: 'spdf.app.module.smartpdf.configuration.sortBy.dateDesc',
    direction: 'desc'
  },
  {
    column: 'date',
    value: 'spdf.app.module.smartpdf.configuration.sortBy.dateAsc',
    direction: 'asc'
  }
];
