import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges
} from '@angular/core';
import {
  createEditableGridConfig,
  EditableGridConfig,
  EditableGridManager,
  FieldConfigDTO,
  GridDatePickerEditorComponent,
  GridSelectEditorComponent,
  GridSelectEditorParams,
  SelectItem,
} from '@basware/gt-editable-grid';
import {
  GravitonGridCommandEventTypes,
  GravitonGridEvent,
  GravitonGridEventTypes,
  GridManager,
  GridRowActionManager,
  GridRowIconHelper,
  GridValidationManager,
  GtGridConfig,
  GTGRID_CONFIG,
  RowActionHelper,
  ROW_ACTION_COLUMN_NAME,
  UpdateRowsCommandEvent,
  RowsUpdatedEvent,
} from '@basware/gt-grid';
import { AgGridEvent, Events, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { BehaviorSubject, combineLatest, Subject, map, takeUntil } from 'rxjs';

import {
  EditableValidatingGridActionManager,
  EditableValidatingGridValidationManager,
} from './managers';
import { InvoiceLineData } from '../invoice.lines.models';
import { BaseTranslationService } from 'src/app/app-services/base.translation.service';
import { LocalizationService } from 'src/app/app-services/localization.service';
import { GlobalizeService } from 'src/app/app-services/globalize.service';
import { AppConfig } from 'src/app/app.config';
import * as moment from 'moment';
import { FormatWidth, getLocaleDateFormat } from '@angular/common';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { AppUtils } from '../../app-util.service';
import { DecimalValidator } from 'src/app/validators/decimal.validator';

// interface InvoiceLineData {
//   invoiceNumber: string;
//   invoiceDate: string;
//   customerName: string;
//   accountCode: string;
//   amount: number;
// }

@Component({
  selector: 'editable-validating-grid',
  templateUrl: './editable-validating-grid.component.html',
  styleUrls: ['./editable-validating-grid.component.scss'],
  providers: [
    EditableGridManager,
    EditableValidatingGridValidationManager,
    { provide: GridManager, useExisting: EditableGridManager },
    {
      provide: GridRowActionManager,
      useClass: EditableValidatingGridActionManager,
    },
    {
      provide: GridValidationManager,
      useExisting: EditableValidatingGridValidationManager,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditableValidatingGridComponent extends BaseTranslationService implements OnInit, OnDestroy {

  @Input() rowData: InvoiceLineData[];
  @Input() isDisabled: boolean = true;
  @Input() reloadGrid: boolean;
  @Output() updatedRowData = new EventEmitter<any>();
  @Output() isGridValid = new EventEmitter<any>();
  @Output() readFocusOnCanvas = new EventEmitter<any>();
  gridConfig: EditableGridConfig<InvoiceLineData>;
  searchText: any;

  /**
   * The application is responsible for tracking data. This is set to
   * true if user makes any changes and enables the save/cancel buttons.
   */
  isDirty$ = new BehaviorSubject<boolean>(false);
  isValid$ = this.gridValidationManager.isValid$;
  isGridValid$ = combineLatest([this.isDirty$, this.isValid$]).pipe(
    map(([isDirty, isValid]) => (isDirty && isValid))
  );

  private destroy$ = new Subject<void>();
  localeTextFunc = (key: string) => {
    return this.tr(key).toString();
  }

  constructor(
    private gridManager: EditableGridManager<InvoiceLineData>,
    private gridValidationManager: EditableValidatingGridValidationManager,
    protected utils: AppUtils,
    localization: LocalizationService,
    @Inject(GTGRID_CONFIG) private gtGridConfig: GtGridConfig
  ) {
    super(localization);
    this.gridConfig = this.createGridConfig();
    this.isGridValid$.subscribe(val => {
      this.isGridValid.emit(val);
    })
  }

  ngOnInit(): void {
    this.subscribeGridEvents();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ("reloadGrid" in changes) {
      if (this.isGridReady) {
        this.setGridData();
      }
    }
    
    if ("isDisabled" in changes) {
      if (this.gridManager?.columnApiQueue.api)
        this.gridSettings(!this.isDisabled);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  clearSearchText() {
    this.searchText = '';
    this.onFilterTextChange();
  }

  onFilterTextChange() {
    this.gridManager.api.setQuickFilter(this.searchText);
  }

  private subscribeGridEvents(): void {
    this.gridManager.events$
      .pipe(takeUntil(this.destroy$))
      .subscribe((event: any) => this.onGridEvents(event));
  }
  
  gridSettings(editable: boolean) {
    // enable/disable column editing
    this.gridManager.columnApi?.getAllGridColumns().forEach(col => {
      if (col.getColId() != "statusIconColId" && col.getColId() != "gridActions")
        col.getColDef().editable = editable
    });
    // disable/enable action buttons of each aggrid row
    let taxGridElement = document.getElementById("line-items-grid") as HTMLElement;
    let taxGridRows = taxGridElement.getElementsByTagName("gt-row-action") as HTMLCollection;
    Array.from(taxGridRows).forEach(el => {
      let actionButton = el.getElementsByTagName("button")[0] as HTMLButtonElement;
      actionButton.disabled = !editable;
      editable ? actionButton.classList.remove("mat-button-disabled") : actionButton.classList.add("mat-button-disabled")
    })
    //disable/enable row selection
    this.gridManager.api.forEachNode(row => {
      row.selectable = editable;
      row.setSelected(false);
    });
  }

  private isGridReady = false;
  private onGridEvents(
    event: GravitonGridEvent | AgGridEvent<InvoiceLineData>
  ): void {
    switch (event.type) {
      case GravitonGridEventTypes.GridReady: {
        this.isGridReady = true;
        this.setGridData();
        this.gridManager.columnApi.autoSizeColumn(ROW_ACTION_COLUMN_NAME);
        break;
      }
      case GravitonGridCommandEventTypes.UpdateRows:
        break;
      case Events.EVENT_CELL_EDITING_STOPPED: {
        // user has edited a cell or added or deleted a row
        this.isDirty$.next(true);
        this.emitRowData();
        break;
      }
      case GravitonGridEventTypes.RowsUpdated: {
        this.isDirty$.next(true);
        this.emitRowData();
        break;
      }
      case Events.EVENT_CELL_EDITING_STARTED: {
        setTimeout(() => {
          let focusedCell = this.gridManager.api.getFocusedCell()
          if (focusedCell && focusedCell.rowIndex > -1) {
            let row = this.gridManager.api.getDisplayedRowAtIndex(focusedCell?.rowIndex);
            if (row) {
              var column = (row.data as any)[focusedCell.column.getColId()]
              if (column && column["data"])
                this.readFocusOnCanvas.emit(column["data"]);
              // else
              //   this.readFocusOnCanvas.emit(column);
            }
          }
        }, 1);
        break;
      }
    }
  }
  _count: number = 0;
  private setGridData(): void {
    this.gridManager.api.setRowData(this.rowData);
    this.isDirty$.next(true);
    this.gridValidationManager.clear();
    this.gridValidationManager.validateGridData();
  }

  addRow(): void {
    let lastRowNumber = this.gridManager.api.getDisplayedRowAtIndex(this.rowData.length - 1)?.data?.rowNumber;
    this._count = (lastRowNumber ?? 0) + 1;
    this.gridManager.dispatch(
      new UpdateRowsCommandEvent({
        add: [new InvoiceLineData(this._count++)],
        update: [],
        remove: [],
      })
    );
  }

  getUpdatedRowData() {
    let _data: any[] = [];
    this.gridManager.api.forEachNode(node => {
      _data.push(node.data)
    })
    return _data;
  }

  emitRowData() {
    this.updatedRowData.emit(this.getUpdatedRowData());
  }

  private defaultFieldConfig = {
    mandatory: false,
    visible: true,
    suppressMenu: true,
    entityType: 'InvoiceLineData',
    flex: 1
  };
  private fieldConfigs: FieldConfigDTO<InvoiceLineData>[] = [
    {
      ...this.defaultFieldConfig,
      bindingPath: 'rowNumber',
      name: { key: '', value: '#' },
      format: 'n',
      readOnly: true,
      mandatory: false,
      flex: 0.4
    },
    {
      ...this.defaultFieldConfig,
      bindingPath: 'productCode',
      name: { key: '', value: this.tr('spdf.app.module.invoice.lines.grid.header.productCode').toString() },
      format: 'text',
      colDefPassthrough: {
        valueSetter: (params) => this._valueSetter(params)
      },
      valueGetter: (params) => this._valueGetter(params)
    },
    {
      ...this.defaultFieldConfig,
      bindingPath: 'description',
      name: { key: '', value: this.tr('spdf.app.module.invoice.lines.grid.header.description').toString() },
      format: 'text',
      mandatory: false,
      flex: 2,
      colDefPassthrough: {
        valueSetter: (params) => this._valueSetter(params)
      },
      valueGetter: (params) => this._valueGetter(params),
    },
    // {
    //   ...this.defaultFieldConfig,
    //   bindingPath: 'date',
    //   name: { key: '', value: 'Date' },
    //   format: 'd',
    //   // colDefPassthrough: {
    //   //   filter: 'agDateColumnFilter',
    //   //   filterParams: dateFilterParams,
    //   // },
    //   valueGetter: (params) => {
    //     if (params.data?.date)
    //       params.data.date = new Date(params.data.date).toLocaleDateString();
    //     return params.data?.date;
    //   },
    //   valueFormatter: (params) => this.dateValueFormatter(params)
    // },
    {
      ...this.defaultFieldConfig,
      bindingPath: 'quantity',
      alignment: "Right",
      name: { key: '', value: this.tr('spdf.app.module.invoice.lines.grid.header.quantity').toString() },
      format: 'text',
      minValue: 0,
      colDefPassthrough: {
        headerClass: "ag-right-aligned-header",
        valueSetter: (params) => this._valueSetter(params)
      },
      valueGetter: (params) => this.decimalValueGetter(params)
    },
    {
      ...this.defaultFieldConfig,
      bindingPath: 'unit',
      name: { key: '', value: this.tr('spdf.app.module.invoice.lines.grid.header.unit').toString() },
      format: 'text',
      mandatory: false,
      colDefPassthrough: {
        valueSetter: (params) => this._valueSetter(params)
      },
      valueGetter: (params) => this._valueGetter(params)
    },
    {
      ...this.defaultFieldConfig,
      bindingPath: 'unitPrice',
      name: { key: '', value: this.tr('spdf.app.module.invoice.lines.grid.header.unitPrice').toString() },
      alignment: "Right",
      format: 'text',
      colDefPassthrough: {
        headerClass: "ag-right-aligned-header",
        valueSetter: (params) => this._valueSetter(params)
      },
      valueGetter: (params) => this.decimalValueGetter(params)
    },
    {
      ...this.defaultFieldConfig,
      alignment: "Right",
      bindingPath: 'taxRate',
      name: { key: '', value: this.tr('spdf.app.module.invoice.lines.grid.header.taxRate').toString() },
      format: 'text',
      colDefPassthrough: {
        headerClass: "ag-right-aligned-header",
        valueSetter: (params) => this._valueSetter(params)
      },
      valueGetter: (params) => this.decimalValueGetter(params),
      valueFormatter: (params) => this.taxRateValueFormatter(params),
    },
    {
      ...this.defaultFieldConfig,
      bindingPath: 'taxSum',
      name: { key: '', value: this.tr('spdf.app.module.invoice.lines.grid.header.taxSum').toString() },
      alignment: "Right",
      format: 'text',
      colDefPassthrough: {
        headerClass: "ag-right-aligned-header",
        valueSetter: (params) => this._valueSetter(params)
      },
      valueGetter: (params) => this.decimalValueGetter(params)
    },
    {
      ...this.defaultFieldConfig,
      alignment: "Right",
      bindingPath: 'netTotal',
      name: { key: '', value: this.tr('spdf.app.module.invoice.lines.grid.header.netTotal').toString() },
      format: 'text',
      colDefPassthrough: {
        headerClass: "ag-right-aligned-header",
        valueSetter: (params) => this._valueSetter(params)
      },
      valueGetter: (params) => this.decimalValueGetter(params)
    },
  ];

  createGridConfig(): EditableGridConfig<InvoiceLineData> {
    return createEditableGridConfig<InvoiceLineData>({
      configDtos: this.fieldConfigs,
      extraColumns: [
        {
          ...GridRowIconHelper.createRowIconColDef(
            this.gridValidationManager,
            this.gtGridConfig
          ),
          cellStyle: { 'justify-content': 'center' },
          suppressMenu: true,
          minWidth: 40,
          maxWidth: 45,
        }, {
          ...RowActionHelper.createActionColDef('Actions'),
          cellStyle: { 'justify-content': 'center' },
          suppressMenu: true,
          minWidth: 40,
          maxWidth: 45,
        }
      ],
      // cellEditors: {
      //   date: {
      //     component: GridDatePickerEditorComponent,
      //   }
      // },
      getRowId: params => params.data.rowNumber.toString(),
      rowSelection: 'single',

    });
  }

  taxRateValueFormatter(params: ValueFormatterParams<InvoiceLineData>) {
    if (params.data && params.data.taxRate) {
      return params.data.taxRate["value"] + '%';
    } else {
      return '%'
    }
  }

  _valueGetter(params: any) {
    let field: any = params.column.getColDef().field as string;
    field = (params.data as any)[field];
    if (field)
      return field["value"];
    return '';
  }

  _valueSetter(params: any) {
    let fieldName: any = params.column.getColDef().field as string;
    let field = (params.data as any)[fieldName];
    if(!params.oldValue && !params.newValue) return true;
    if(params.oldValue == params.newValue) return true;
    if (!field) {
      params.data[fieldName] = { "value": "", data: {} }
    }
    params.data[fieldName]["value"] = params.newValue.trim();
    return true;
  }

  decimalValueGetter(params: any) {
    let field = params.column.getColDef().field as string;
    if (!(params.data as any)[field]) {
      return '';
    }
    let value = (params.data as any)[field]["value"];
    if (value) {
      if (!DecimalValidator.decimalValidatorForValue(value) && !isNaN(Number(GlobalizeService.unformatNumber(value, AppConfig.USER_CULTURE)))) {
        value = AppUtils.localizeDecimal(value);
      };
    }
    else value = '';
    (params.data as any)[field]["value"] = value;
    return value;
  }

  dateValueFormatter(params: ValueFormatterParams<InvoiceLineData>) {
    let field = params.column.getColDef().field as string;
    let value = (params.data as any)[field];
    if (value) {
      let dateVal = moment.utc(value).locale(AppConfig.USER_CULTURE || AppConfig.BROWSER_CULTURE);
      console.log(dateVal.format('L'))
      console.log(dateVal.localeData().longDateFormat('L'))
      return dateVal.format('L');
    }
    else return '';
  }
}


/**
 * filterParams for AgGrid's agDateColumnFilter
 * Cell data must be an ISO8601 formatted string.
 */
const dateFilterParams = {
  comparator: (filterLocalDateAtMidnight: Date, cellValue: string): number => {
    const dateAsString = cellValue;
    if (dateAsString == null) return -1;
    // date in grid must be an ISO8601 string which is parseable by native Date
    const parsedCellDate = new Date(dateAsString);
    // AgGrid date filter compares against local date at midnight
    // Create a new date in local time, dropping the time and time zone information from
    // parsed cell value, which should match to what was selected in the filter UI
    const cellDate = new Date(
      parsedCellDate.getUTCFullYear(),
      parsedCellDate.getUTCMonth(),
      parsedCellDate.getUTCDate()
    );
    if (cellDate.getTime() === filterLocalDateAtMidnight.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
    return -1;
  },
  browserDatePicker: true,
};
