<ng-container *ngIf="_taxData.length == 0">
    <mat-expansion-panel hideToggle [expanded]="true">
        <div class="empty-state-custom gt-empty-state-panel-body-container" [ngClass.xs]="{'gt-centered-content':true}">
            <div class="gt-row">
                <p> {{tr('spdf.app.module.tax.data.no.data')}} </p>
            </div>
            <div class="gt-row">
                <button mat-stroked-button color="primary" (click)="addTaxData()" [disabled]="isDisabled">
                    {{tr('spdf.app.module.bu.form.taxesAndSums.Add.taxline')}}
                </button>
            </div>
        </div>
    </mat-expansion-panel>
</ng-container>
<ng-container *ngIf="_taxData.length > 0">
    <taxes-editable-validating-grid [rowData]="_taxData" [isDisabled]="isDisabled" [reloadGrid]="reloadGrid"
        (updatedRowData)="updatedRowData($event)" (isGridValid)="_isTaxDataValid($event)"
        (readFocusOnCanvas)="_readFocusOnCanvas($event)"></taxes-editable-validating-grid>
</ng-container>