import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { AgGridEvent, ColDef, GridReadyEvent } from 'ag-grid-community';
import { Observable, Subject, takeUntil, catchError, forkJoin, map, of } from 'rxjs';
import { supplierInfo } from './supplierInfo';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { BaseTranslationService } from 'src/app/app-services/base.translation.service';
import { LocalizationService } from 'src/app/app-services/localization.service';
import { ApiService } from 'src/app/app-services/api.service';
import { CommonService } from 'src/app/app-services/common.service';
import {
  GravitonGridEvent,
  GravitonGridEventTypes,
  GridConfig,
  GridManager
} from "@basware/gt-grid";
import { SelectionModel } from '@angular/cdk/collections';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-dialog',
  templateUrl: './supplier-dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  providers: [GridManager]
})
export class SupplierGridComponent extends BaseTranslationService implements OnInit, AfterViewInit {
  gridConfig: GridConfig<supplierInfo>;

  agGridApi: any;
  searchText: any;
  canSubmit = false;
  flexValue: number;

  public supplierInfo: any;
  public additionalInfo: any;
  bankAccInfo: any;
  public noSupplier: boolean = false;
  private destroy$ = new Subject<void>();
  rowSelection = new SelectionModel<any>(true, []);

  rowSelect(row: any) {
    this.rowSelection.clear();
    this.rowSelection.select(row);
    this.bankAccInfo = {
      'bankAccount': row['number'],
      'iban': row['iban']
    };
  }

  bankRowDoubleClick(row: any) {
    this.rowSelection.clear();
    this.rowSelection.select(row);
    this.selectSupplier(row["number"], row["iban"]);
  }

  columnDefs: ColDef[] = [
    { field: 'companyCode', hide: true },
    { headerName: this.tr('spdf.app.dialog.supplier.section1.subheading1').toString(), field: 'supplierCode', width: 100, resizable: true },
    { headerName: this.tr('spdf.app.dialog.supplier.section1.subheading2').toString(), field: 'supplierName', resizable: true, comparator: customComparator, sortable: true, sort: 'asc' },
    { field: 'bankAccounts', hide: true },
    { field: 'supplierStreet', hide: true },
    { field: 'supplierCity', hide: true },
    { field: 'supplierPostalCode', hide: true },
    { field: 'phone', hide: true },
    { field: 'vat', hide: true },
    { field: 'einvoiceAddress', hide: true }
  ];

  rowData$!: Observable<any[]>;

  constructor(private gridManager: GridManager<supplierInfo>, public dialogRef: MatDialogRef<SupplierGridComponent>, @Inject(MAT_DIALOG_DATA) public dialogData: any,
    localization: LocalizationService, public apiService: ApiService, private commonService: CommonService) {
    super(localization);
    this.gridConfig = this.createGridConfig();
  }
  ngAfterViewInit(): void {
    this.resizeObserver();
  }

  ngOnInit(): void {
    this.supplierInfo = new supplierInfo();
    this.supplierInfo.supplierName = this.tr('spdf.app.dialog.supplier.noSupplier.dummyText').toString()
    this.supplierInfo.supplierCode = '-';
    this.subscribeGridEvents();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private createGridConfig(): GridConfig<supplierInfo> {
    return {
      options: {
        rowSelection: "single",
        columnDefs: this.columnDefs,
        getRowId: (params) => params.data["supplierCode"]?.toString(),
      },
    };
  }

  onCellClicked(data: any) {
    this.canSubmit = true;
    this.supplierInfo = data;
    this.supplierInfo.bankAccounts?.sort((a: any, b: any) => a.number?.toLowerCase().localeCompare(b.number?.toLowerCase()));
    let supplierStreet = data.supplierStreet ? data.supplierStreet : '';
    let supplierCity = data.supplierCity ? (supplierStreet ? ', ' : '') + data.supplierCity : '';
    let supplierPostalCode = data.supplierPostalCode ? (supplierStreet || supplierCity ? ', ' : '') + data.supplierPostalCode : '';
    this.supplierInfo.address = supplierStreet + supplierCity + supplierPostalCode;
  }

  onFilterTextChange() {
    this.gridManager.api.setQuickFilter(this.searchText);
  }

  private subscribeGridEvents(): void {
    this.gridManager.events$
      .pipe(takeUntil(this.destroy$))
      .subscribe((event) => this.onGridEvents(event));
  }

  private onGridEvents(event: GravitonGridEvent | AgGridEvent<supplierInfo>): void {
    switch (event.type) {
      case GravitonGridEventTypes.GridReady: {
        this.loadData();

        this.gridManager.api.addGlobalListener((type: string, e: any) => {

          if (type === "rowClicked" || type === "selectionChanged") {
            this.onCellClicked(this.gridManager.api.getSelectedRows()[0])
          }
          if (type === "cellDoubleClicked") {
            this.selectSupplier();
          }
        });
        break;
      }
    }
  }

  autoSizeAll() {
    // this.gridManager.columnApi.autoSizeAllColumns(false);
    this.gridManager.api.sizeColumnsToFit();
  }

  getSupplierData(url: string) {
    return this.apiService.GetSupplierData(url).pipe(map((res: supplierInfo[]) => {
      res = res.filter(x => x.supplierCode && x.supplierName)
      return ({ success: true, data: res });
    }), catchError((error: HttpErrorResponse) => {
      return of({ error: error, success: false, data: null });
    }))
  }

  mergeSupplierData(res: any[]) {
    let mergedData: supplierInfo[] = [];
    res.forEach(x => {
      if (x.success && x.data) {
        mergedData = mergedData.concat(x.data)
      }
    })
    return mergedData;
  }

  loadData() {
    let urls = this.commonService.getVendorUrl();
    forkJoin([this.getSupplierData(urls[0]), this.getSupplierData(urls[1])]).subscribe(res => {
      let mergedData = this.mergeSupplierData(res);
      if (mergedData.length == 0) {
        this.noSupplier = true;
        this.supplierInfo.supplierName = this.tr('spdf.app.dialog.supplier.noSupplier.dummyText').toString()
        this.supplierInfo.supplierCode = '-';
        this.emptyStateResizeObserver();
        return;
      }
      this.gridManager.api.setRowData(mergedData);
      this.autoSizeAll();
      this.gridManager.api.forEachNode(node => {
        if (node.data?.supplierCode == '-' && 'UKNOWN_VENDOR' == this.dialogData.supplierCode) {
          node.setSelected(true);
        }
        if (node.data?.supplierCode == this.dialogData.supplierCode) {
          node.setSelected(true);
        }
      });
    }, error => {
      this.noSupplier = true;
      this.supplierInfo.supplierName = this.tr('spdf.app.dialog.supplier.noSupplier.dummyText').toString()
      this.supplierInfo.supplierCode = '-';
      this.emptyStateResizeObserver();
      return;
    });
  }

  selectSupplier(bankAccount?: any, iban?: any) {
    let supplierCode = this.supplierInfo.supplierCode;
    let supplierData = {
      supplierCode: supplierCode,
      supplierName: this.supplierInfo.supplierName,
      bankAccount: (bankAccount ? bankAccount : (this.bankAccInfo ? this.bankAccInfo.bankAccount : '')),
      iban: (iban ? iban : (this.bankAccInfo ? this.bankAccInfo.iban : ''))
    }
    this.dialogRef.close(supplierData);
    this.canSubmit = false;
  }

  clearSearchText() {
    this.searchText = '';
    this.onFilterTextChange();
  }

  resizeObserver() {
    //resize observer for fields pane
    new ResizeObserver(x => {
      let panelWidth = (document.getElementsByClassName('additional-info')[0] as HTMLDivElement)?.offsetWidth;
      if (panelWidth > 0 && panelWidth < 480) {
        this.flexValue = 100
      } else if (panelWidth > 479 && panelWidth < 720) {
        this.flexValue = 50
      } else {
        this.flexValue = 33.33
      }
    }).observe(document.getElementsByClassName('additional-info')[0]);

    new ResizeObserver(x => {
      let dialogHeight = (document.getElementsByClassName('mat-dialog-content')[0] as HTMLDivElement)?.clientHeight - 68;
      if (dialogHeight) {
        (document.getElementsByClassName('bank-details')[0] as HTMLDivElement).style.height = (.5 * dialogHeight) - 85 + 'px';
        (document.getElementsByClassName('additional-info')[0] as HTMLDivElement).style.height = (.5 * dialogHeight) - 43.4 + 'px';
        (document.getElementsByClassName('supplier-list')[0] as HTMLDivElement).style.height = dialogHeight + 'px';
      }
    }).observe(document.getElementsByClassName('mat-dialog-content')[0]);
  }

  emptyStateResizeObserver() {
    new ResizeObserver(x => {
      let dialogHeight = (document.getElementsByClassName('mat-dialog-content')[0] as HTMLDivElement)?.clientHeight - 68;
      if (dialogHeight) {
        (document.getElementsByClassName('empty-supplier-list')[0] as HTMLDivElement).style.height = dialogHeight - 2 + 'px';
      }
    }).observe(document.getElementsByClassName('mat-dialog-content')[0]);
  }
}


const customComparator = (valueA: string, valueB: string) => {
  return valueA?.toLowerCase().localeCompare(valueB?.toLowerCase());
};