import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, catchError, from, of, switchMap, tap, throwError } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CacheAPIService {
    private cacheName = 'smartPDF-cache-v1';
    constructor(private http: HttpClient) { }

    getCachedOrFetch(url: string, saveFullUrl: boolean = true): Observable<any> {
        let cachedUrl = saveFullUrl ? url : url.split('?')[0];
        return from(caches.match(cachedUrl, { cacheName: this.cacheName })).pipe(
            switchMap((response) => {
                if (response) {
                    // Found response in cache
                    return response.json();
                }
                // No response found in cache. About to fetch from network
                return this.http.get<any>(url).pipe(
                    tap((networkResponse) => {
                        caches.open(this.cacheName).then((cache) => {
                            // Response stored in cache
                            cache.put(cachedUrl, new Response(JSON.stringify(networkResponse)));
                        });
                    }),
                    catchError((error) => {
                        // Error fetching data
                        return throwError(() => error);
                    })
                );
            })
        );
    }

    clearCacheStorage(){
        caches.delete(this.cacheName);
    }
}