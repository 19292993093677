import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { SubSink } from 'subsink';
import { ApiService } from '../app-services/api.service';
import { BaseTranslationService } from '../app-services/base.translation.service';
import { CommonService } from '../app-services/common.service';
import { LocalizationService } from '../app-services/localization.service';
import { AppConfig } from '../app.config';
import { AppUtils } from '../dynamic-form/app-util.service';
import { PageEvent } from '@angular/material/paginator';
import { SmartpdfApiService } from '../app-services/smartpdf.api.service';
import { ConfigurationReport } from '../models/ConfigurationReport';
import { BusinessUnit } from '../models/businessUnit';
import { environment } from 'src/environments/environment';
declare function aptrinsic(t: any, s: any): any;

export interface KeyValuePair {
    value: string;
    viewValue: string;
}

@Component({
    selector: 'app-business-unit',
    templateUrl: './business-unit.component.html',
    styleUrls: ['./business-unit.component.scss']
})
export class BusinessUnitComponent extends BaseTranslationService implements OnInit, OnDestroy {

    subs = new SubSink();
    flexValue: number;
    isAdmin : boolean;

    constructor(public common: CommonService, public apiService: ApiService, public appUtils: AppUtils, private router: Router,
        localization: LocalizationService, private smartpdfApiService: SmartpdfApiService) {
        super(localization);
        this.subs.add(this.common.flexValue.subscribe(
            value => { this.flexValue = value }
        ))
        this.isAdmin = this.common.isAdmin();
    }

    async ngOnInit() {
        this.buDataSource.filterPredicate = (myObject: any, filter: any) => {
            let filterObj = JSON.parse(filter);
            if (this.hideEmptyUnits && myObject.messageCount == 0) {
                return false;
            }
            if (((filterObj.businessUnit && filterObj.businessUnit != '') && myObject.businessUnit.toLowerCase().includes(filterObj.businessUnit))
                || ((filterObj.invoiceAddress && filterObj.invoiceAddress != '') && myObject.invoiceAddress.toLowerCase().includes(filterObj.invoiceAddress))
            ) {
                return true;
            } else {
                return false;
            }
        }
        if (environment.approvalFlowFeatureFlag) {
            this.getPendingConfigurations();
        }
        await this.getBusinessUnits();
        this.sortParameters = this.appUtils.GetBUSortParams();
        this.applySortFilter(this.sortParameters[0]);
    }

    ngAfterViewInit() {
        this.buDataSource.paginator = this.paginator;
        //setting the user session defined page size
        if (sessionStorage.getItem('businessUnitPageSize') != null) {
            this.pageSize = Number(sessionStorage.getItem('businessUnitPageSize'));
        }
        aptrinsic('track', 'smartPdfQueueListPageLoaded');
    }

    @ViewChild(MatPaginator) paginator: MatPaginator;
    panelOpenState: boolean;
    matPaginatorPageSizeOptions = AppConfig.MATERIAL_PAGINATOR_PAGE_SIZE_OPTIONS;
    pageSize = AppConfig.DEFAULT_PAGE_SIZE;
    displayedColumns: string[] = ['businessUnit', 'invoiceAddress', 'invoiceEmail', 'messageCount', 'actions'];
    displayedColumnsWithName: string[] = ['businessUnit', 'configurationName', 'invoiceAddress', 'invoiceEmail', 'messageCount', 'actions'];
    businessUnits = [];
    buDataSource = new MatTableDataSource(this.businessUnits);
    noUnitsTodisplay = false;
    hideEmptyUnits: boolean = true;
    sortParameters: KeyValuePair[] = [];
    defaultSortParam = AppConfig.BU_SORT_PARAMS_KEYMAP.BUSINESS_UNIT_ASC;
    // separate variable is required as datasource length isn't updated when filtered
    totalUnits = 0;
    buName = '';
    value = AppConfig.PROGRESS_BAR_VALUE;
    bufferValue = AppConfig.PROGRESS_BAR_BUFFER_VALUE;
    isContentLoading = true;
    pendingConfigs: ConfigurationReport[] = [];

    //toggle business unit selection 
    toggleUnitSelection(e: any) {

        let tempList = this.filterEmptyQueues(e.checked);
        this.buDataSource.data = tempList;
        this.totalUnits = this.buDataSource.data.length;
        this.applySortFilter(this.sortParameters[0]);
    }

    // fetch business units and sort
    async fetchBUs() {
        await this.getBusinessUnits();
        this.applySortFilter(this.sortParameters[0]);
    }
    //fetch business units
    async getBusinessUnits() {
        try {
            this.isContentLoading = true;
            let data: any = await this.apiService.GetBusinessUnits();
            this.isContentLoading = false;
            if (data) {
                this.businessUnits = data.queuesData;
                if (this.common.isAdmin() || this.isMultipleConfigs(this.businessUnits)) {
                    this.displayedColumns = this.displayedColumnsWithName;
                }
                this.noUnitsTodisplay = true ? data.queuesData.length == 0 : false;
                this.buDataSource.data = this.filterEmptyQueues(this.hideEmptyUnits);
                this.totalUnits = this.buDataSource.data.length;
            }
        } catch (err) {
            //handle error
        }
    }

    isMultipleConfigs(units: BusinessUnit[]) {
        let configId = units[0].configurationId;
        for (let i = 0; i < units.length; i++) {
            if (units[i].configurationId != configId) {
                return true;
            }
        }
        return false;
    }

    //apply sort by Filter
    applySortFilter(event: any) {
        let sortedData = this.buDataSource.data.sort((a, b) => {
            const isAsc = event.value.split('-')[1] == 'ASC' ? true : false;
            const sortParam = event.value.split('-')[0];
            switch (sortParam) {
                case 'businessUnit': return this.compare(String(a['businessUnit']).toLowerCase(), String(b['businessUnit']).toLowerCase(), isAsc);
                case 'invoiceAddress': return this.compare(String(a['invoiceAddress']).toLowerCase(), String(b['invoiceAddress']).toLowerCase(), isAsc);
                case 'messageCount': return this.compare(parseInt(a['messageCount']), parseInt(b['messageCount']), isAsc);
                default: return 0;
            }
        });
        this.buDataSource.data = sortedData;
        this.totalUnits = this.buDataSource.data.length;
    }
    compare(a: number | string, b: number | string, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    //apply Filter
    applyFilter(filterValue: any) {
        filterValue = filterValue.trim().toLowerCase(); // Remove whitespace
        if (!filterValue) {
            this.buDataSource.filter = '';
            this.buDataSource.data = this.filterEmptyQueues(this.hideEmptyUnits);
            this.totalUnits = this.buDataSource.data.length;
            return;
        }
        this.buDataSource.filter = JSON.stringify({ businessUnit: filterValue, invoiceAddress: filterValue });
        this.totalUnits = this.buDataSource.filteredData.length
    }

    //apply hide empty queue filter
    filterEmptyQueues(hideEmpty: boolean) {
        let filteredBUs = JSON.parse(JSON.stringify(this.businessUnits));
        if (hideEmpty) {
            filteredBUs = filteredBUs.filter((bu: any) => bu['messageCount'] != '0');
        } else {
            filteredBUs = this.businessUnits;
        }
        this.noUnitsTodisplay = true ? filteredBUs.length == 0 : false;
        return filteredBUs;
    }

    //get business unit & redirect
    getBusinessUnit(data: any) {
        if (data.messageCount == 0) {
            return false;
        }
        sessionStorage.setItem('businessUnit', data.businessUnit);
        sessionStorage.setItem('invoiceAddress', data.invoiceAddress);
        sessionStorage.setItem('unitIdentifier', data.unitIdentifier);
        sessionStorage.setItem('isLineItemsActive', data.isLineItemsActive);
        sessionStorage.setItem('configurationApproval', JSON.stringify({ name: data.configurationName, isPending: this.isConfigPendingForApproval(data.configurationId) }));
        this.router.navigate(["/business-unit"], {
            queryParams: {
                cfid: data.configurationId,
                ieml: data.invoiceEmail,
                uuid: data.unitUuid,
                ia: data.invoiceAddress
            }
        });
    }

    clearFilterValue() {
        this.buName = '';
        this.applyFilter('');
    }

    //capture page size or page index change
    onChangePage(pe: PageEvent) {
        if (pe.pageSize != AppConfig.DEFAULT_PAGE_SIZE) {
            sessionStorage.setItem('businessUnitPageSize', pe.pageSize.toString());
        }
    }

    getPendingConfigurations() {
        this.smartpdfApiService.getConfigurations().subscribe({
            next: (data) => {
                this.pendingConfigs = data.filter(report => report.approval.status == "Pending Approval");
            }
        })
    }

    isConfigPendingForApproval(configId: string) {
        return this.pendingConfigs.filter(config => config.configurationId == configId).length > 0
    }
    
    ngOnDestroy(): void {
        //clear cached apis when new unit is clicked
        this.apiService.clearCacheApis();
    }
}
