import { Injectable } from '@angular/core';
import { BaseTranslationService } from '../app-services/base.translation.service';
import { LocalizationService } from '../app-services/localization.service';
import { AppConfig } from '../app.config';
import { AgGridTemplate } from './element-aggrid';

import { ElementBase } from './element-base';
import { DatePickerTemplate } from './element-datepicker';
import { DropdownTemplate } from './element-dropdown';
import { DropdownWithLookupTemplate } from './element-dropdown-with-lookup';
import { NumberTemplate } from './element-number';
import { TextboxTemplate } from './element-textbox';
import { ChipTemplate } from './element-chip';

@Injectable()
export class FieldService extends BaseTranslationService {

    constructor(localization: LocalizationService) {
        super(localization);
    }

    //get Fields from ocr data
    generateFields(data: [], configFields: any[]) {
        let fields: ElementBase<any>[] = [];
        if (configFields && configFields.length > 0) {
            let ocrElement: any;
            let ibanFields = [];
            let bbanFields = [];
            for (var i = 0; i < configFields.length; i++) {
                let configField: any = configFields[i];
                ocrElement = data.filter((ocrElem, idx, arr) => {
                    return ocrElem['name'] == configField['fieldCode'];
                });
                if (ocrElement.length == 0) {
                    ocrElement = null;
                } else if (ocrElement.length == 1 && configField['fieldCode'] != 'IBAN_NUMBER' && configField['fieldCode'] != 'BANK_ACCOUNT_VALUE') {
                    ocrElement = ocrElement[0];
                }
                if (!ocrElement) {
                    ocrElement = {
                        height: '',
                        width: '',
                        string: '',
                        name: configField['fieldName'],
                        x: '',
                        y: '',
                        page: '',
                    };

                }
                // ocrElement['name'] = configField['fieldName'];
                // ocrElement['dataType'] = configField['dataType'];
                let field: any;
                switch (configField!['dataType'].toLowerCase()) {
                    case 'string':
                        if (configField['fieldCode'] == 'DOCUMENT_TYPE_VALUE') {
                            field = new DropdownTemplate({
                                key: ocrElement['name'],
                                label: ocrElement['name'],
                                value: ocrElement['string'] || '',
                                defaultValue: configField['defaultValue'],
                                options:
                                    AppConfig.DOCUMENT_TYPE_LIST,
                                required: configField['mandatory'],
                                uiOrder: configField['uiOrder'],
                                x: ocrElement['x'],
                                y: ocrElement['y'],
                                width: ocrElement['width'],
                                height: ocrElement['height'],
                                readonly: false,
                                isVatIdField: false,
                                isIbanField: false,
                                page: ocrElement['page'],
                                fieldCode: configField['fieldCode'],
                                fieldType: 'dropdown',
                                minLength: configField['minLength'],
                                maxLength: configField['maxLength'],
                                fieldTypeClassification: configField['fieldType']
                            })
                        } else if (configField['fieldCode'] == 'SUPPLIER_NUMBER') {
                            field = new AgGridTemplate({
                                key: ocrElement['name'],
                                label: ocrElement['name'],
                                value: ocrElement['string'] || '',
                                defaultValue: configField['defaultValue'],
                                required: configField['mandatory'],
                                uiOrder: configField['uiOrder'],
                                x: ocrElement['x'],
                                y: ocrElement['y'],
                                width: ocrElement['width'],
                                height: ocrElement['height'],
                                readonly: false,
                                isVatIdField: false,
                                isIbanField: false,
                                page: ocrElement['page'],
                                fieldCode: configField['fieldCode'],
                                fieldType: 'string',
                                minLength: configField['minLength'],
                                maxLength: configField['maxLength'],
                                fieldTypeClassification: configField['fieldType']
                            })
                        } else if (configField['fieldCode'] == 'CURRENCY') {
                            field = new DropdownWithLookupTemplate({
                                key: ocrElement['name'],
                                label: ocrElement['name'],
                                value: ocrElement['string'] || '',
                                defaultValue: configField['defaultValue'],
                                required: configField['mandatory'],
                                uiOrder: configField['uiOrder'],
                                x: ocrElement['x'],
                                y: ocrElement['y'],
                                width: ocrElement['width'],
                                height: ocrElement['height'],
                                readonly: false,
                                isVatIdField: false,
                                isIbanField: false,
                                page: ocrElement['page'],
                                fieldCode: configField['fieldCode'],
                                fieldType: 'string',
                                minLength: configField['minLength'],
                                maxLength: configField['maxLength'],
                                fieldTypeClassification: configField['fieldType']
                            })
                        } else if (configField['fieldCode'] == 'IBAN_NUMBER') {
                            //below special instance check is required in case no captured data is found for IBAN_NUMBER
                            if (!(ocrElement instanceof Array)) {
                                ocrElement = [ocrElement];
                            }
                            for (let i = 0; i < ocrElement.length; i++) {
                                if (ocrElement[i]['string']) {
                                    let ibanField: any = {
                                        key: ocrElement[i]['name'] + (i + 1),
                                        label: ocrElement[i]['name'],
                                        value: ocrElement[i]['string'].trim().replace(/^:+/, '') || '',			//remove all leading colons 
                                        defaultValue: configField['defaultValue'],
                                        required: configField['mandatory'],
                                        uiOrder: configField['uiOrder'],
                                        x: ocrElement[i]['x'],
                                        y: ocrElement[i]['y'],
                                        width: ocrElement[i]['width'],
                                        height: ocrElement[i]['height'],
                                        readonly: false,
                                        isVatIdField: (configField['fieldCode'] == 'SUPPLIER_VAT_NUMBER' ? true : false),
                                        isIbanField: (configField['fieldCode'] == 'IBAN_NUMBER' ? true : false),
                                        page: ocrElement[i]['page'],
                                        fieldCode: configField['fieldCode'],
                                        fieldType: configField['fieldType'],
                                        minLength: configField['minLength'],
                                        maxLength: configField['maxLength'],
                                        fieldTypeClassification: configField['fieldType']
                                    };
                                    ibanFields.push(ibanField);
                                }
                            }
                            field = new ChipTemplate({
                                key: ocrElement[0]['name'],
                                label: ocrElement[0]['name'],
                                value: JSON.stringify(ibanFields) || '',
                                defaultValue: configField['defaultValue'],
                                required: configField['mandatory'],
                                uiOrder: configField['uiOrder'],
                                readonly: false,
                                isVatIdField: (configField['fieldCode'] == 'SUPPLIER_VAT_NUMBER' ? true : false),
                                isIbanField: (configField['fieldCode'] == 'IBAN_NUMBER' ? true : false),
                                fieldCode: configField['fieldCode'],
                                fieldType: 'string',
                                minLength: configField['minLength'],
                                maxLength: configField['maxLength'],
                                fieldTypeClassification: configField['fieldType'],
                                controlType: 'chip'
                            })
                        } else if (configField['fieldCode'] == 'BANK_ACCOUNT_VALUE') {
                            //below special instance check is required in case no captured data is found for BBAN_NUMBER
                            if (!(ocrElement instanceof Array)) {
                                ocrElement = [ocrElement];
                            }
                            for (let i = 0; i < ocrElement.length; i++) {
                                if (ocrElement[i]['string']) {
                                    let bbanField: any = {
                                        key: ocrElement[i]['name'] + (i + 1),
                                        label: ocrElement[i]['name'],
                                        value: ocrElement[i]['string'].trim().replace(/^:+/, '') || '',//remove all leading colons 
                                        defaultValue: configField['defaultValue'],
                                        required: configField['mandatory'],
                                        uiOrder: configField['uiOrder'],
                                        x: ocrElement[i]['x'],
                                        y: ocrElement[i]['y'],
                                        width: ocrElement[i]['width'],
                                        height: ocrElement[i]['height'],
                                        readonly: false,
                                        isVatIdField: (configField['fieldCode'] == 'SUPPLIER_VAT_NUMBER' ? true : false),
                                        isIbanField: (configField['fieldCode'] == 'IBAN_NUMBER' ? true : false),
                                        page: ocrElement[i]['page'],
                                        fieldCode: configField['fieldCode'],
                                        fieldType: configField['fieldType'],
                                        minLength: configField['minLength'],
                                        maxLength: configField['maxLength'],
                                        fieldTypeClassification: configField['fieldType']
                                    };
                                    bbanFields.push(bbanField);
                                }
                            }
                            field = new ChipTemplate({
                                key: ocrElement[0]['name'],
                                label: ocrElement[0]['name'],
                                value: JSON.stringify(bbanFields) || '',
                                defaultValue: configField['defaultValue'],
                                required: configField['mandatory'],
                                uiOrder: configField['uiOrder'],
                                readonly: false,
                                isVatIdField: (configField['fieldCode'] == 'SUPPLIER_VAT_NUMBER' ? true : false),
                                isIbanField: (configField['fieldCode'] == 'IBAN_NUMBER' ? true : false),
                                fieldCode: configField['fieldCode'],
                                fieldType: 'string',
                                minLength: configField['minLength'],
                                maxLength: configField['maxLength'],
                                fieldTypeClassification: configField['fieldType']
                            })
                            field.controlType = 'bban-chip';
                        } else {
                            field = new TextboxTemplate({
                                key: ocrElement['name'],
                                label: ocrElement['name'],
                                value: ocrElement['string'] || '',
                                defaultValue: configField['defaultValue'],
                                required: configField['mandatory'],
                                uiOrder: configField['uiOrder'],
                                x: ocrElement['x'],
                                y: ocrElement['y'],
                                width: ocrElement['width'],
                                height: ocrElement['height'],
                                readonly: false,
                                isVatIdField: (configField['fieldCode'] == 'SUPPLIER_VAT_NUMBER' ? true : false),
                                isIbanField: (configField['fieldCode'] == 'IBAN_NUMBER' ? true : false),
                                page: ocrElement['page'],
                                fieldCode: configField['fieldCode'],
                                fieldType: 'string',
                                minLength: configField['minLength'],
                                maxLength: configField['maxLength'],
                                fieldTypeClassification: configField['fieldType']
                            })
                        }
                        if (configField['fieldCode'] == 'SUPPLIER_NAME') {
                            field.readonly = true;
                        }
                        break;
                    case 'decimal':
                        field = new TextboxTemplate({
                            key: ocrElement['name'],
                            label: ocrElement['name'],
                            value: ocrElement['string'] || '',
                            defaultValue: configField['defaultValue'],
                            required: configField['mandatory'],
                            uiOrder: configField['uiOrder'],
                            x: ocrElement['x'],
                            y: ocrElement['y'],
                            width: ocrElement['width'],
                            height: ocrElement['height'],
                            readonly: false,
                            isVatIdField: false,
                            isIbanField: false,
                            page: ocrElement['page'],
                            fieldCode: configField['fieldCode'],
                            fieldType: 'decimal',
                            minLength: configField['minLength'],
                            maxLength: configField['maxLength'],
                            fieldTypeClassification: configField['fieldType']
                        })
                        break;
                    case 'integer':
                        field = new TextboxTemplate({
                            key: ocrElement['name'],
                            label: ocrElement['name'],
                            value: ocrElement['string'] || '',
                            defaultValue: configField['defaultValue'],
                            required: configField['mandatory'],
                            uiOrder: configField['uiOrder'],
                            x: ocrElement['x'],
                            y: ocrElement['y'],
                            width: ocrElement['width'],
                            height: ocrElement['height'],
                            readonly: false,
                            isVatIdField: false,
                            isIbanField: false,
                            page: ocrElement['page'],
                            fieldCode: configField['fieldCode'],
                            fieldType: 'integer',
                            minLength: configField['minLength'],
                            maxLength: configField['maxLength'],
                            fieldTypeClassification: configField['fieldType']
                        })
                        break;
                    case 'dropdown':
                        field = new DropdownTemplate({
                            key: ocrElement['name'],
                            label: ocrElement['name'],
                            value: ocrElement['string'] || '',
                            defaultValue: configField['defaultValue'],
                            options:
                                AppConfig.DOCUMENT_TYPE_LIST,
                            required: configField['mandatory'],
                            uiOrder: configField['uiOrder'],
                            x: ocrElement['x'],
                            y: ocrElement['y'],
                            width: ocrElement['width'],
                            height: ocrElement['height'],
                            readonly: false,
                            isVatIdField: false,
                            isIbanField: false,
                            page: ocrElement['page'],
                            fieldCode: configField['fieldCode'],
                            fieldType: 'dropdown',
                            minLength: configField['minLength'],
                            maxLength: configField['maxLength'],
                            fieldTypeClassification: configField['fieldType']
                        })
                        break;
                    case 'date':
                        field = new DatePickerTemplate({
                            key: ocrElement['name'],
                            label: ocrElement['name'],
                            value: ocrElement['string'] || ' ',
                            defaultValue: configField['defaultValue'],
                            required: configField['mandatory'],
                            uiOrder: configField['uiOrder'],
                            x: ocrElement['x'],
                            y: ocrElement['y'],
                            width: ocrElement['width'],
                            height: ocrElement['height'],
                            readonly: false,
                            isVatIdField: false,
                            isIbanField: false,
                            page: ocrElement['page'],
                            fieldCode: configField['fieldCode'],
                            fieldType: 'date',
                            minLength: configField['minLength'],
                            maxLength: configField['maxLength'],
                            fieldTypeClassification: configField['fieldType']
                        })
                        break;
                }
                if(!field) continue; // field will be missing in case of 'List' datatype
                //hide certain fields: SPDF-158
                if (AppConfig.HIDDEN_FIELDS.indexOf(field.fieldCode) != -1
                    || field.fieldCode == 'NA') {
                    field.hidden = true;
                }
                if (configField['fieldType'] == "Standard") {
                    field.label = this.tr('spdf.app.field.labels.' + field.fieldCode);
                }
                if (!AppConfig.ADDRESS_HIDDEN_FIELDS.includes(field.fieldCode)) {
                    fields.push(field);
                }
            }
        }
        return fields.sort((a, b) => a.uiOrder - b.uiOrder);;
    }
}