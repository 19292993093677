import { Pipe, PipeTransform } from '@angular/core';
import { ElementBase } from './element-base';

class filterType {
    taxesAndSumsSection : boolean
}

@Pipe({
    name: 'fieldSectionPipe',
    pure: false
})
export class InvoiceFormFieldSectionPipe implements PipeTransform {
    transform(items: ElementBase<any>[], filter: filterType): any {
        if (!items || !filter) {
            return items;
        }
        // filter items array, items which match and return true will be
        // kept, false will be filtered out
        return items.filter(item => filter.taxesAndSumsSection? item.taxesAndSumsSection : !item.taxesAndSumsSection);
    }
}